$(document).ready(function(){
    if($('#map_canvas').length){
        displayOSMMap();
    }   
});

/* WYŚWIETLA MAPĘ OSM NA STRONIE GŁÓWNEJ */
function displayOSMMap(){
    var latlng = [gmap_params.center_lat, gmap_params.center_long];
    var mymap = L.map('map_canvas', {
        center: latlng,
        zoom: gmap_params.zoom,
        maxZoom: 18
    });
    mymap.scrollWheelZoom.disable();

//https://mapy.galactica.pl/osm/{z}/{x}/{y}.png
//https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
    L.tileLayer('https://mapy.galactica.pl/osm/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(mymap);

    var markericon = L.icon({
        iconUrl: ($("#map_canvas").length && $("#map_canvas").data("marker")) ? $("#map_canvas").data("marker") : 'images/icons/map-icon.svg',
        iconSize:  ($("#map_canvas").length && $("#map_canvas").data("marker")) ? [108, 104] : [65, 78],   
        iconAnchor:   ($("#map_canvas").length && $("#map_canvas").data("marker")) ? [54, 94] : [33, 67],
    });
    var markers = L.markerClusterGroup({ spiderfyOnMaxZoom: true, showCoverageOnHover: true, zoomToBoundsOnClick: true });
    var bounds = new L.LatLngBounds();
    
    for(i=0; i < gmap_params['markers'].length; i++){
        
        var marker = L.marker([gmap_params.markers[i].lat, gmap_params.markers[i].long], {icon: markericon, id: gmap_params.markers[i].id});
        if (gmap_params.markers[i].id) {
            marker.bindPopup("Wczytywanie...");
            
            marker.on('click', function(e) {
                var popup = e.target.getPopup();
                var handler = gmap_params.handler;
                var id = e.target.options.id;
                var url = "handler/"+handler+"/"+id;
                $.ajax({
                    url: url,
                    cache: false,
                    dataType: 'json',
                    success: function(data){
                        if(data){
                            infoWindowContent = data.marker;
                            popup.setContent(infoWindowContent);
                            popup.update();
                        }
                    }
                });
            });
        }
        
        bounds.extend(marker.getLatLng());
        markers.addLayer(marker);
    }
    
    if(gmap_params['markers'].length > 1){
        mymap.fitBounds(bounds, {padding: [10, 10]});
    }
    mymap.addLayer(markers);
}

    